'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import List from './list';
import TrackCard from './cards/track-card';
import mapSlice from './map-slice';


export default class TrackList extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({
      activities_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired
    })),
    isDeviceTrack: PropTypes.bool.isRequired,
    trackDevice: PropTypes.func.isRequired
  };

  render() {
    const {page, pageSize, tracks, isDeviceTrack, onSelect, trackDevice} = this.props;
    return (
      <List>{mapSlice(page * pageSize, (page + 1) * pageSize, track =>
        <TrackCard key={track.activities_id} track={track} onClick={onSelect} isDeviceTrack={isDeviceTrack} trackDevice={trackDevice}/>, tracks)}
      </List>
    );
  }
}