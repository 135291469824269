'use strict';

// import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import ContextMenu from './context-menus/context-menu';
import HelpMenu from './context-menus/help-menu';
import EventList from './lists/event-list';
// import DeviceList from './lists/device-list';
import TrackList from './lists/track-list';
import Devices from './pages/devices';
import EONAdmin, { EONAdminMenu, EONAdminData } from './pages/eon-admin';
import { IntlProvider } from 'react-intl';

import Events from './pages/events';


if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en'); // Add locale data for en
  require('@formatjs/intl-pluralrules/dist/locale-data/hu'); // Add locale data for hu
  // require('@formatjs/intl-pluralrules/dist/locale-data/de'); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en'); // Add locale data for en
  require('@formatjs/intl-relativetimeformat/dist/locale-data/hu'); // Add locale data for hu
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/de'); // Add locale data for de
}

import hu_msgs from '../locales/hu.json';
import en_msgs from '../locales/en.json';


Object.assign(global, {
  Events, React, ReactDOM, IntlProvider, ContextMenu, HelpMenu, EventList, Devices, TrackList,
  EONAdmin, EONAdminMenu, EONAdminData,
  messages: { hu: hu_msgs, en: en_msgs }
});
