'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import './table.css';

const SearchableTable = ({ columns, rows, match, onColumnClick, onRowClick, rowId }) => (
  <div className='table'>
    <div className='table--heading'>{columns.map((column, i) =>
      <span key={column.name} onClick={event => onColumnClick && onColumnClick(column, i, event)} className={`heading--cell table--cell-${column.name}`}>{column.label}</span>)}
    </div>{rows.map((row, i) =>
    <div key={rowId(row)} onClick={event => onRowClick && onRowClick(row, i, event)} className={'table--row' + (match && match(row) ? ' search-match' : '')}>{columns.map(column =>
      <span key={column.name} className={`column table--cell-${column.name}`}>
        <span className='row--heading'>{column.label + ': '}</span>{row[column.name]}
      </span>)}
    </div>)}
  </div>
);

SearchableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string
  })),
  rows: PropTypes.array,
  match: PropTypes.func,
  onColumnClick: PropTypes.func,
  onRowClick: PropTypes.func,
  rowId: PropTypes.func.isRequired
};

export default SearchableTable;
