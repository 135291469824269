'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import List from './list';
import EventCard from './cards/event-card';
import mapSlice from './map-slice';

export default class EventList extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    events: PropTypes.arrayOf(PropTypes.shape({
      cda_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired
    }))
  };

  render() {
    const {page, pageSize, events, onSelect} = this.props;
    return (
      <List>{mapSlice(page * pageSize, (page + 1) * pageSize, event =>
        <EventCard key={event.cda_id} event={event} onClick={onSelect} />, events)}
      </List>
    );
  }
}