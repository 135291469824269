/* @flow */
'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ContextWrapper from './context-wrapper.jsx';
import ContextMenuItem from './context-menu-item.jsx';

export default class ContextMenu extends Component {
  render() {
    const {items, delegate, ...wrapperProps} = this.props;
    return (
      <ContextWrapper {...wrapperProps}>
        {items.map(item => <ContextMenuItem key={item.label} {...item} delegate={delegate} />)}
      </ContextWrapper>
    );
  }
}

ContextMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired
  })).isRequired,
  delegate: PropTypes.shape({
    dispatch: PropTypes.func.isRequired
  }).isRequired
}
