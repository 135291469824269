'use strict';

const mapSlice = (begin, end, callback, array) => {
  const result = [];
  const length = array.length;
  while (begin < end && begin < length) {
    result.push(callback(array[begin], begin, array));
    begin += 1;
  }
  return result;
};

export default mapSlice;