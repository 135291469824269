'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedRelativeTime} from 'react-intl';

import {Card, CardItem, CardSubItem, CardMiscItem, CardGizmo, CardNonBreaking} from './card';

function inRange(min, max, value) {
  return value >= min && value <= max;
}

function color(r, g, b) {
  if (inRange(0, 255, r) && inRange(0, 255, g) && inRange(0, 255, b)) {
    return `rgb(${r},${g},${b})`;
  }
}


export default class EventCard extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    event: PropTypes.shape({
      interventionNeeded: PropTypes.bool.isRequired,
      ev_descr: PropTypes.string.isRequired,
      arrivedDate: PropTypes.number.isRequired,
      cda_account: PropTypes.string.isRequired,
      cu_name: PropTypes.string.isRequired,
      ec_color_r: PropTypes.string,
      ec_color_g: PropTypes.string,
      ec_color_b: PropTypes.string,
      cda_event: PropTypes.string.isRequired
    })
  };

  _handleClick = () => this.props.onClick(this.props.event);

  render() {
    const {
      ec_color_r: r, ec_color_g: g, ec_color_b: b,
      interventionNeeded,
      ev_descr: description,
      arrivedDate: arrived,
      cda_account: account,
      cu_name: name,
      cda_event: code
    } = this.props.event;

    let misc = [<CardNonBreaking key="account">{account}</CardNonBreaking>];
    if (name !== account) {
      misc = [...misc, ' ', <CardNonBreaking key="name">{name}</CardNonBreaking>];
    }
    misc = [...misc, ` | ${code}`];

    return (
      <Card color={color(parseFloat(r), parseFloat(g), parseFloat(b))} isBorderColored={interventionNeeded} onClick={this._handleClick}>
        <CardItem>{description}</CardItem>
        <CardSubItem>
          <FormattedDate year='numeric' month='long' day='2-digit' hour='2-digit' minute='2-digit' second='2-digit' value={arrived}/>
        </CardSubItem>
        <CardSubItem>
          <FormattedRelativeTime value={(arrived - Date.now()) / 1000} units="second" updateIntervalInSeconds={1} />
        </CardSubItem>
        <CardMiscItem>{misc}</CardMiscItem>
        <CardGizmo />
      </Card>
    );
  }
}