'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';
import arrayEquals from 'array-equal';

import PureCheckbox from './pure-checkbox';
import DeviceFilter from './device-filter';
import {devicesShape} from './device-filter';
import DescriptionFilter from './description-filter';
import CategoryFilter from './category-filter';
import PeriodFilter from './period-filter';

const messages = defineMessages({
  areAllDevicesSelected: {
    id: 'pages.events.areAllDevicesSelected',
    description: 'Label for checkbox to enable / disable event filtering by devices',
    defaultMessage: 'Minden készülék'
  },
  areAllCategoriesSelected: {
    id: 'pages.events.areAllCategoriesSelected',
    description: 'Label for checkbox to enable / disable event filter by categories',
    defaultMessage: 'Minden kategória'
  }
});

export {devicesShape as devicesShape};

const eventShape = PropTypes.shape({
  partner_api_key: PropTypes.string.isRequired,
  cda_account: PropTypes.string.isRequired,
  ev_category_ref: PropTypes.string.isRequired,
  ev_category_name: PropTypes.string
});

export const eventsShape = PropTypes.arrayOf(eventShape);

function getDeviceMap(devices, deviceIDs) {
  return deviceIDs.reduce((deviceMap, id) => {
    const device = devices.find(d => d.id === id);
    const {partner_api_key: partnerApiKey, account} = device;
    if (!deviceMap[partnerApiKey]) {
      deviceMap[partnerApiKey] = Object.create(null);
    }
    deviceMap[partnerApiKey][account] = device;
    return deviceMap;
  }, Object.create(null));
}

function categoryComparer(a, b) {
  return a.key < b.key ? -1 : a.key > b.key ? 1 : 0;
}

function getCategories(events) {
  const categories = Object.create(null);
  for (const {
    ev_category_ref: categoryID,
    ev_category_name: categoryName
  } of events) {
    if (!categories[categoryID]) {
      categories[categoryID] = categoryName;
    }
  }
  return Object.keys(categories).sort(categoryComparer).map(key => ({ key, value: categories[key] || null }));
}


const deviceFilter = deviceMap => ({ partner_api_key, cda_account }) =>
  deviceMap[partner_api_key] && deviceMap[partner_api_key][cda_account];

const categoryFilter = categoryIDs => ({ ev_category_ref }) =>
  categoryIDs.indexOf(ev_category_ref) !== -1;

const descriptionFilter = parts => ({ ev_descr }) =>
  parts.some(part => (ev_descr || '').toLowerCase().indexOf(part) !== -1);


const blockAll = Symbol('Block all');
const allowAll = Symbol('Allow all');

const composeFilters = (period, otherFilters) => events => {
  const arrivedLimit = Date.now() - 1000 * 60 * 60 * period;
  return events.filter(event => event.arrivedDate >= arrivedLimit && otherFilters.every(filter => filter(event)));
};


class EventFilterForm extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    devices: devicesShape.isRequired,
    events: eventsShape.isRequired,
    onPeriodChanged: PropTypes.func.isRequired,
    onFilterUpdated: PropTypes.func.isRequired,
    periods: PropTypes.arrayOf(PropTypes.shape({
      hours: PropTypes.number.isRequired
    })).isRequired,
    initialPeriod: PropTypes.number.isRequired
  };

  static defaultProps = {
    periods: [{hours: 1}, {hours: 6}, {hours: 12}, {hours: 24}, {hours: 48}, {hours: 72}, {hours: 168}],
    initialPeriod: 1
  };

  state = {
    selectedPeriod: this.props.initialPeriod,
    allDevices: true,
    allCategories: true,
    selectedDeviceIDs: [],
    selectedCategoryIDs: [],
    categories: [],
    descriptionParts: []
  };

  componentDidUpdate(prevProps) {
    if (this.props.events !== prevProps.events && this._deviceFilter !== blockAll) {
      this.changeCategories(this.props.events, this._deviceFilter);
    }
  }

  _deviceFilter = allowAll;
  _categoryFilter = allowAll;
  _descriptionFilter = allowAll;


  changeFilter(period) {
    if (this._deviceFilter !== blockAll && this._categoryFilter !== blockAll) {
      this.props.onFilterUpdated(composeFilters(period, [
        this._descriptionFilter,
        this._categoryFilter, this._deviceFilter
      ].filter(filter => filter !== allowAll)));
    } else {
      this.props.onFilterUpdated(() => []);
    }
  }

  changeCategories(events, deviceFilter) {
    const nextCategories = deviceFilter === blockAll
      ? []
      : deviceFilter === allowAll
        ? getCategories(events)
        : getCategories(events.filter(deviceFilter));

    if (!arrayEquals(nextCategories, this.state.categories)) {
      this.setState({categories: nextCategories});
    }
  }

  changeDeviceFilter(allDevices, devices, selectedDeviceIDs) {
    const nextDeviceFilter = (!allDevices) && selectedDeviceIDs.length > 0 && selectedDeviceIDs.length < devices.length
      ? deviceFilter(getDeviceMap(devices, selectedDeviceIDs))
      : (!allDevices) && selectedDeviceIDs.length === 0
        ? blockAll
        : allowAll;

    if (this._deviceFilter !== nextDeviceFilter) {
      this.changeCategories(this.props.events, nextDeviceFilter);
      this._deviceFilter = nextDeviceFilter;
      this.changeFilter(this.state.selectedPeriod);
    }
  }

  changeCategoryFilter(allCategories, categories, selectedCategoryIDs) {
    const nextCategoryFilter = (!allCategories) && selectedCategoryIDs.length > 0 && selectedCategoryIDs.length < categories.length
      ? categoryFilter(selectedCategoryIDs)
      : (!allCategories) && selectedCategoryIDs.length === 0
        ? blockAll
        : allowAll;

    if (this._categoryFilter !== nextCategoryFilter) {
      this._categoryFilter = nextCategoryFilter;
      this.changeFilter(this.state.selectedPeriod);
    }
  }

  changeDescriptionFilter(nextParts) {
    if (!arrayEquals(this.state.descriptionParts, nextParts)) {
      this.setState({
        descriptionParts: nextParts
      });
      this._descriptionFilter = nextParts.length === 0
        ? allowAll
        : descriptionFilter(nextParts);
      this.changeFilter(this.state.selectedPeriod);
    }
  }

  _handlePeriodSelected = ({target:{value}}) => {
    value = Number(value);
    this.setState({
      selectedPeriod: value
    });
    this.props.onPeriodChanged(value);
    this.changeFilter(value);
  };


  _handleDescriptionFilterChanged = parts => {
    this.changeDescriptionFilter(parts/*.map(part => part.trim().toLowerCase())*/);
  };


  _handleChangeIsDeviceFiltered = ({target:{checked:allDevices}}) => {
    this.setState({allDevices});
    this.changeDeviceFilter(allDevices, this.props.devices, this.state.selectedDeviceIDs);
    
  };
  
  _handleDevicesSelected = selectedDeviceIDs => {
    this.setState({selectedDeviceIDs});
    this.changeDeviceFilter(this.state.allDevices, this.props.devices, selectedDeviceIDs);
  };

  _handleChangeIsCategoryFiltered = ({target:{checked:allCategories}}) => {
    this.setState({allCategories});
    this.changeCategoryFilter(allCategories, this.state.categories, this.state.selectedCategoryIDs);
  };
  
  _handleCategoriesSelected = selectedCategoryIDs => {
    this.setState({selectedCategoryIDs});
    this.changeCategoryFilter(this.state.allCategories, this.state.categories, selectedCategoryIDs);
  };

  render() {
    const {
      periods,
      devices,
      intl: {
        formatMessage
      }
    } = this.props;
    
    const {
      selectedPeriod,
      categories,
      allDevices,
      allCategories,
      selectedDeviceIDs,
      selectedCategoryIDs
    } = this.state;

    const {
      _handlePeriodSelected,
      _handleDescriptionFilterChanged,
      _handleChangeIsDeviceFiltered,
      _handleChangeIsCategoryFiltered,
      _handleDevicesSelected,
      _handleCategoriesSelected
    } = this;

    return (
      <div className='filter-form pure-form pure-form-stacked'>
        <PeriodFilter selectedPeriod={selectedPeriod} periods={periods} onPeriodSelected={_handlePeriodSelected} />
        <DescriptionFilter onFilterChanged={_handleDescriptionFilterChanged} />
        {devices.length > 0 ? <PureCheckbox checked={allDevices} onChange={_handleChangeIsDeviceFiltered} label={formatMessage(messages.areAllDevicesSelected)} /> : null}
        {devices.length === 0 || allDevices ? null : <DeviceFilter devices={devices} selectedDeviceIDs={selectedDeviceIDs} onDevicesSelected={_handleDevicesSelected} />}
        {categories.length > 0 ? <PureCheckbox checked={allCategories} onChange={_handleChangeIsCategoryFiltered} label={formatMessage(messages.areAllCategoriesSelected)} /> : null}
        {categories.length === 0 || allCategories ? null : <CategoryFilter categories={categories} selectedCategoryIDs={selectedCategoryIDs} onCategoriesSelected={_handleCategoriesSelected} />}
      </div>
    );
  }
}

export default injectIntl(EventFilterForm, { forwardRef: true });