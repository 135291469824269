'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import PageContent from './page-content';
import EmptyInfo from './empty-info';
import DeviceList from '../lists/device-list';

function handleDevicesUpdate(devicesComponent) {
  return function (devicesModel) {
    devicesComponent.setState({
      devices: devicesModel.getActive(),
      initialized: devicesModel.timestamp !== null
    });
  };
}

function setDevicesModel(devicesComponent, devicesModel) {
  devicesComponent.modelUpdateBinding = devicesModel.updated.add(handleDevicesUpdate(devicesComponent));
  handleDevicesUpdate(devicesComponent)(devicesModel);
}

const messages = defineMessages({
  devicesEmpty: {
    id: 'pages.devices.empty',
    description: 'Label displayed if there are no devices.',
    defaultMessage: 'Nincs készülék hozzáadva.'
  }
});

export default class Devices extends Component {
  static propTypes = {
    deviceSelected: PropTypes.shape({
      dispatch: PropTypes.func.isRequired
    }).isRequired,
    devicesModel: PropTypes.shape({
      updated: PropTypes.shape({
        add: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired
      }).isRequired,
      getActive: PropTypes.func.isRequired,
      timestamp: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number.isRequired])
    }).isRequired
  };
  
  constructor(props, context) {
    super(props, context);
    this.state = {
      devices: props.devicesModel.getActive(),
      initialized: props.devicesModel.timestamp !== null
    };
  }

  componentDidMount() {
    setDevicesModel(this, this.props.devicesModel);
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.devicesModel !== prevProps.devicesModel) {
      this.modelUpdateBinding.detach();
      setDevicesModel(this, this.props.devicesModel);
    }
  }

  componentWillUnmount() {
    this.modelUpdateBinding.detach();
  }

  _handleSelect = device => this.props.deviceSelected.dispatch(device);

  render() {
    const {initialized, devices} = this.state;
    return (
      <PageContent>{initialized
        ? devices.length > 0
          ? <DeviceList devices={devices} onSelect={this._handleSelect} />
          : <EmptyInfo><FormattedMessage {...messages.devicesEmpty} /></EmptyInfo>
        : null
      }</PageContent>
    );
  }
}
