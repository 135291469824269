/*global utils*/
'use strict';

import React, { Component } from 'react';
import { useIntl, injectIntl } from 'react-intl';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import {ActionButtons, ActionButton, ActionLabel, ActionIcon} from './action-button';
import SearchableTable from './table';
import PureCheckbox from './event-filter-form/pure-checkbox';
import DeviceFilter, { devicesShape } from './event-filter-form/device-filter';

import './eon-admin.css';

const dateTimeFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
};



function zeroOffset(last_counter, imps_for_m) {
  return {
    target: {
      name: 'offset',
      value: (-parseFloat(last_counter) / parseFloat(imps_for_m)).toFixed(3)
    }
  };
}

function corrOffset(last_counter, imps_for_m, offset) {
  return {
    target: {
      name: 'offset',
      value: ((parseFloat(prompt('Aktuálist számlálóállás', offset).replace(/,/g, '.')) || 0) - (parseFloat(last_counter) / parseFloat(imps_for_m))).toFixed(3)
    }
  };
}

const DeviceCounterForm = ({ counter: { offset, imps_for_m, last_counter, last_counter_ts, comment }, changed, onChange, onSubmit }) => {
  const intl = useIntl();

  return (
    <form className='pure-form pure-form-stacked' style={{padding: '1em 0.5em 0 0.5em'}} onSubmit={onSubmit}>
      <label>{
        `Utolsó számlálóállás${last_counter && last_counter_ts ? ` (${intl.formatDate(utils.date.parseString(last_counter_ts), dateTimeFormat)})` : ''}`}
      </label>
      <input className='pure-input-1' value={last_counter && last_counter_ts ?`${parseFloat(offset || 0).toFixed(3)} m3 + ${last_counter} \u00D7 ${1.0 / parseFloat(imps_for_m)} m3 = ${(parseFloat(offset) + parseFloat(last_counter) / parseFloat(imps_for_m)).toFixed(3)} m3` : 'nincs'} readOnly />
      <label htmlFor='imps_for_m' className={Object.prototype.hasOwnProperty.call(changed, 'imps_for_m') ? 'changed-label' : ''}>Impulzus egyenérték</label>
      <select id='imps_for_m' name='imps_for_m' className='pure-input-1' value={imps_for_m} onChange={onChange}>
        <option value='1'>1 m3</option>
        <option value='10'>0.1 m3</option>
        <option value='100'>0.01 m3</option>
        <option value='1000'>0.001 m3</option>
      </select>
      <fieldset className='pure-group'>
        <label htmlFor='offset' className={Object.prototype.hasOwnProperty.call(changed, 'offset') ? 'changed-label' : ''} style={{display:'inline-block'}}>Induló számlálóállás:</label>
        {` ${(parseFloat(offset) || 0).toFixed(3)} `}m<sup>3</sup>
        <div className='pure-g'>
          <div className='pure-u-1-2' style={{padding: '0.2em', boxSizing: 'border-box'}}>
            <button type='button' disabled={!last_counter} className='pure-button pure-button pure-input-1' onClick={() => onChange(zeroOffset(last_counter, imps_for_m))}>Nullázás</button>
          </div>
          <div className='pure-u-1-2' style={{padding: '0.2em', boxSizing: 'border-box'}}>
            <button type='button' disabled={!last_counter} className='pure-button pure-button pure-input-1' onClick={() => onChange(corrOffset(last_counter, imps_for_m))}>Korrigálás</button>
          </div>
        </div>
      </fieldset>
      <label htmlFor='comment' className={Object.prototype.hasOwnProperty.call(changed, 'comment') ? 'changed-label' : ''}>Megjegyzés</label>
      <input id ='comment' name='comment' className='pure-input-1' value={comment} onChange={onChange} />
      <button type="submit" className='pure-button pure-button-primary pure-input-1' disabled={Object.keys(changed).length === 0} style={{marginBottom: '0.5em'}}>Mentés</button>
    </form>
  );
};

export const EONAdminMenu = ({ closeAction, openConfig, openData, hasData, hasConfig }) => (
  <div className='overlay-root'>
    <header onClick={closeAction} className='overlay-header'>
      <i className='overlay-header-icon fa fa-fw fa-angle-left' />
      <h1 className='overlay-title'>E.ON admin - menu</h1>
    </header>
    <div className='overlay-content'>
      <ActionButtons>{hasConfig ?
        <ActionButton onClick={openConfig}>
          <ActionIcon><i className='fa fa-fw fa-cog' style={{paddingLeft: '0.5em'}} /></ActionIcon>
          <ActionLabel>E.ON config</ActionLabel>
        </ActionButton> : null}{hasData ?
        <ActionButton onClick={openData}>
          <ActionIcon><i className='fa fa-fw fa-list' style={{paddingLeft: '0.5em'}} /></ActionIcon>
          <ActionLabel>E.ON data</ActionLabel>
        </ActionButton> : null}
      </ActionButtons>
    </div>
  </div>
);

EONAdminMenu.propTypes = {
  closeAction: PropTypes.func.isRequired,
  openConfig: PropTypes.func.isRequired,
  openData: PropTypes.func.isRequired,
  hasData: PropTypes.bool,
  hasConfig: PropTypes.bool
};

const data_columns = [{name:'name',label: 'Eszköz'},{name:'sent',label:'Utolsó üzenet'},{name:'value',label:'Utolsó mért érték'}];

function generateCSV(data) {
  const csv = data.map(({ name, sent, value }) => `"${name}";"${sent.toISOString()}";"${value}"`).join('\n');
  return 'data:text/csv;charset=utf-8,' + encodeURIComponent(`"ID";"TIMESTAMP";"VALUE"\n${csv}`);
}

function concatEONData(data, devices, allDevices, selectedDeviceIDs) {
  if (!allDevices && selectedDeviceIDs.size < devices.length) {
    devices = selectedDeviceIDs.size > 0 ? devices.filter(device => selectedDeviceIDs.has(device.id)) : [];
  }

  return Object.keys(data).reduce((rows, partner) => {
    data[partner].forEach(({ account, sent, value }) => {
      const device = devices.filter(device => device.account === account && device.partner_api_key === partner)[0];
      if (device) {
        rows.push({ _id: shortid.generate(), name: device.name, sent: utils.date.parseString(sent), value });
      }
    });
    return rows;
  }, []);
}

function getId(row) {
  return row._id;
}

const structuredEonDataShape = PropTypes.objectOf(PropTypes.shape({
  account: PropTypes.string.isRequired,
  sent: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}));

class EONAdminDataB extends Component {
  static propTypes = {
    devices: devicesShape,
    monthly: structuredEonDataShape,
    hourly: structuredEonDataShape,
    intl: PropTypes.object.isRequired,
    closeAction: PropTypes.func.isRequired
  };

  state = { monthly: [], hourly: [], allDevices: true, selectedDeviceIDs: new Set(), type: 'hourly' };

  componentDidUpdate(prevProps) {
    if (this.props.devices !== prevProps.devices || this.props.monthly !== prevProps.monthly) {
      const { monthly, devices } = this.props;
      const { allDevices, selectedDeviceIDs } = this.state;
      this.setState({ monthly: concatEONData(monthly, devices, allDevices, selectedDeviceIDs) });
    }

    if (this.props.devices !== prevProps.devices || this.props.hourly !== prevProps.hourly) {
      const { hourly, devices } = this.props;
      const { allDevices, selectedDeviceIDs } = this.state;
      this.setState({ hourly: concatEONData(hourly, devices, allDevices, selectedDeviceIDs) });
    }
  }

  filterData() {
    const { monthly, hourly, devices } = this.props;
    const { allDevices, selectedDeviceIDs } = this.state;
    this.setState({
      monthly: concatEONData(monthly, devices, allDevices, selectedDeviceIDs),
      hourly: concatEONData(hourly, devices, allDevices, selectedDeviceIDs)
    });
  }

  handleChangeIsDeviceFiltered = event => {
    this.setState({ allDevices: event.target.checked }, () => this.filterData());
  };

  handleDevicesSelected = selectedDeviceIDs => {
    this.setState({ selectedDeviceIDs: new Set(selectedDeviceIDs) }, () => this.filterData());
  };

  onChangeType = event => this.setState({ type: event.target.value });

  render() {
    const { closeAction, devices, intl } = this.props;
    const { monthly, hourly, allDevices, selectedDeviceIDs, type } = this.state;
    const { handleChangeIsDeviceFiltered, handleDevicesSelected, onChangeType } = this;
    return (
      <div className='overlay-root'>
        <header onClick={closeAction} className='overlay-header'>
          <i className='overlay-header-icon fa fa-fw fa-angle-left' />
          <h1 className='overlay-title'>E.ON admin - data</h1>
        </header>
        <div className='overlay-content'>
          <form className='pure-form pure-form-stacked filter-form'>
            <label htmlFor='eon-data-type'>Felbontás</label>
            <select id='eon-data-type' name='eon-data-type' className='pure-input-1' value={type} onChange={onChangeType}>
              <option value='hourly'>Óránkénti</option>
              <option value='monthly'>Havi</option>
            </select>
            <PureCheckbox checked={allDevices} onChange={handleChangeIsDeviceFiltered} label={'Minden készülék'} />
            {devices.length === 0 || allDevices ? null : <DeviceFilter devices={devices} selectedDeviceIDs={[...selectedDeviceIDs]} onDevicesSelected={handleDevicesSelected} />}
          </form>{type === 'hourly' ?
          <div style={{padding:'0.5em'}}>
            <a style={{display:'block',textAlign:'right',paddingRight:'0.5em'}} href={generateCSV(hourly)} download='hourly.csv'>Exportálás (.csv)</a>
            <h3 style={{position:'relative'}}>Óránkénti bontás az utolsó 3 hónapra</h3>
            <SearchableTable columns={data_columns} rows={hourly.map(({ _id, name, sent, value }) => ({ _id, name, sent: intl.formatDate(sent, dateTimeFormat), value }))} rowId={getId} />
          </div> :
          <div style={{padding:'0.5em'}}>
            <a style={{display:'block',textAlign:'right',paddingRight:'0.5em'}} href={generateCSV(monthly)} download='monthly.csv'>Exportálás (.csv)</a>
            <h3 style={{position:'relative'}}>Havi bontás az utolsó 13 hónapra</h3>
            <SearchableTable columns={data_columns} rows={monthly.map(({ _id, name, sent, value }) => ({ _id, name, sent: intl.formatDate(sent, dateTimeFormat), value }))} rowId={getId} />
          </div>}
        </div>
      </div>
    );
  }
}

export const EONAdminData = injectIntl(EONAdminDataB);

export default class EONAdmin extends Component {
  static propTypes = {
    devices: devicesShape,
    counters: PropTypes.objectOf(PropTypes.objectOf(PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])))),
    updateCounter: PropTypes.func.isRequired,
    closeAction: PropTypes.func.isRequired
  };

  state = { selectedDevice: 'none' };

  handleChangeDevice = event => this.setState({ selectedDevice: event.target.value, tmp: {} });

  handleChange = event => {
    const { selectedDevice, tmp } = this.state;
    const { devices, counters } = this.props;
    devices.some(device => {
      if (device.id === selectedDevice) {
        const counter = counters[device.partner_api_key][device.account];
        if (counter[event.target.name] === event.target.value) {
          delete tmp[event.target.name];
        } else {
          tmp[event.target.name] = event.target.value;
        }
        // console.log(tmp);
        return true;
      }
    });
    this.setState({ tmp });
  };

  handleSubmit= event => {
    event.preventDefault();
    event.stopPropagation();
    const { selectedDevice, tmp } = this.state;
    const { updateCounter } = this.props;
    updateCounter(selectedDevice, tmp);
    this.setState({ tmp: {} });
  };

  componentDidUpdate(prevProps) {
    if (this.props.counters !== prevProps.counters) {
      this.setState({ tmp: {} });
    }
  }

  render() {
    const { closeAction, counters, devices } = this.props;
    const deviceCounters = devices.reduce((dcs, device) => {
      const counter = counters[device.partner_api_key] && counters[device.partner_api_key][device.account];
      if (counter) {
        dcs.push({ device, counter });
      }
      return dcs;
    }, []);

    const { selectedDevice, tmp } = this.state;
    const selectedDeviceCounter = (selectedDevice && selectedDevice !== 'none') ? deviceCounters.filter(dc => dc.device.id === selectedDevice)[0] : null;
    const { handleChangeDevice, handleChange, handleSubmit } = this;
    return (
      <div className='overlay-root'>
        <header onClick={closeAction} className='overlay-header'>
          <i className='overlay-header-icon fa fa-fw fa-angle-left' />
          <h1 className='overlay-title'>E.ON admin - config</h1>
        </header>
        <div className='overlay-content'>
          <form className='pure-form pure-form-stacked' style={{padding: '1em 0.5em 0 0.5em'}}>
            <label htmlFor='name'>Név (gyári szám)</label>
            <select id='name' name='name' className='pure-input-1' onChange={handleChangeDevice} value={selectedDevice}><option value='none'>-</option>{deviceCounters.map(dc =>
              <option key={dc.device.id} value={dc.device.id}>{dc.device.name || dc.device.account}</option>)}
            </select>
          </form>{selectedDeviceCounter ?
          <DeviceCounterForm counter={Object.assign({}, selectedDeviceCounter.counter, tmp)} onChange={handleChange} changed={tmp} onSubmit={handleSubmit} /> : null}
        </div>
      </div>
    );
  }
}