'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedDate} from 'react-intl';

import {Card, CardItem, CardSubItem, CardMiscItem, CardNonBreaking, CardGizmo} from './card';

import './track-card.css';

const addressFirstLine = ({country, postalCode, cityAndDistrict, street}) => {
  let text = '' +
    (country && (country + ', ')) +
    (postalCode && (postalCode + ' ')) +
    (cityAndDistrict || '');
  if (text.length > 0 && street && street.length > 0) {
    text = text + ', ';
  }
  return text.length > 0 ? text : null;
};


const addressSecondLine = ({street, streetNumber}) => {
  let text = '';
  if (street) {
    text += street;
    if (streetNumber) {
      text += ' ' + streetNumber + '.';
    }
  }
  return text.length > 0 ? text : null;
};

const Address = props => {
  const firstLine = addressFirstLine(props);
  const secondLine = addressSecondLine(props);
  if (firstLine || secondLine) {
    return (
      <CardItem className='trip-item'>
        {firstLine && <CardNonBreaking>{firstLine}</CardNonBreaking>}
        {secondLine && <CardNonBreaking>{secondLine}</CardNonBreaking>}
      </CardItem>
    );
  }
  return null;
};

export default class TrackCard extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    track: PropTypes.shape({
      megtettut: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      startTime_tstz: PropTypes.number.isRequired,
      stopTime_tstz: PropTypes.number.isRequired,
      startAddress: PropTypes.object,
      stopAddress: PropTypes.object
    }).isRequired,
    trackDevice: PropTypes.func.isRequired
  };

  _handleClick = () => this.props.onClick(this.props.track);

  render() {
    const {track, trackDevice} = this.props;
    let miscItems = [<CardNonBreaking key='distance'>{track.megtettut + ' km'}</CardNonBreaking>];
    const device = trackDevice(track);
    if (device) {
      miscItems = [<CardNonBreaking key='name'>{device.name}</CardNonBreaking>, ', ', ...miscItems];
      if (device.name !== device.account) {
        miscItems = [<CardNonBreaking key='account'>{device.account}</CardNonBreaking>, ' ', ...miscItems];
      }
    }
    return (
      <Card onClick={this._handleClick}>
        <Address {...track.startAddress} />
        <CardSubItem className='trip-item'>
          <FormattedDate year='numeric' month='long' day='2-digit' hour='2-digit' minute='2-digit' second='2-digit' value={track.startTime_tstz}/>
        </CardSubItem>
        <Address {...track.stopAddress} />
        <CardSubItem className='trip-item'>
          <FormattedDate year='numeric' month='long' day='2-digit' hour='2-digit' minute='2-digit' second='2-digit' value={track.stopTime_tstz}/>
        </CardSubItem>
        <CardMiscItem className='trip-item'>{miscItems}</CardMiscItem>
        <CardGizmo />
      </Card>
    );
  }
}