'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import List from './list';
import DeviceCard from './cards/device-card';

export default class DeviceList extends Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    devices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired
    }))
  };

  render = () =>
    <List>{this.props.devices.map(device =>
      <DeviceCard key={device.id} device={device} onClick={this.props.onSelect} />)}
    </List>;
}