'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './list.css';

class List extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const children = this.props.children;
    return React.Children.count(children) === 0
      ? null
      : <div className='list'>{children}</div>;
  }
}

export default List;