'use strict';
import React from 'react';
import PropTypes from 'prop-types';

const PureCheckbox = ({checked, onChange, label}) => (
  <label className='pure-checkbox'>
    <input type='checkbox' checked={checked} onChange={onChange}/>{label && ' '}{label}
  </label>
);

PureCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default PureCheckbox;