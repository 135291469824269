/* @flow */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import './context-wrapper.css';

const ContextWrapper = ({title, closeAction, titleImg, children}) => (
  <div>
    <div key='bg' className='context-bg' onClick={closeAction}></div>
    <div key='content' className='context-content'> {titleImg ? (
      <div key='title' className='context-title' onClick={closeAction}>
        <img className='context-title-img' src={titleImg}></img>
        <span className='context-title-text'>{title}</span>
      </div>) :
      <div key='title' className='context-title' onClick={closeAction}>{title}</div>}
      <div key='list' className={'context-list' + ((React.Children.count(children) % 2 === 0) ? ' even' : '')}>
        {children}
      </div>
    </div>
  </div>
);

ContextWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  closeAction: PropTypes.func.isRequired,
  titleImg: PropTypes.string,
  children: PropTypes.node
};

export default ContextWrapper;