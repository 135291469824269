/*global i18n*/
'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './context-menu-item.css';

export default class ContextMenuItem extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    delegate: PropTypes.shape({
      dispatch: PropTypes.func.isRequired
    }).isRequired,
    label: PropTypes.string.isRequired
  };

  _handleClick = () => this.props.action(this.props.delegate);

  render() {
    return (
      <div className='context-item' onClick={this._handleClick}>
        {i18n.t(this.props.label)}
      </div>
    );
  }
}
