'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

const deviceShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  account: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const devicesShape = PropTypes.arrayOf(deviceShape);

export default class DeviceFilter extends Component {
  static propTypes = {
    devices: devicesShape.isRequired,
    selectedDeviceIDs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    onDevicesSelected: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedDeviceIDs: []
  };

  componentDidUpdate(prevProps) {
    const {devices, selectedDeviceIDs, onDevicesSelected} = this.props;

    if (prevProps.devices !== devices) {
      const nextSelectedDeviceIDs = selectedDeviceIDs.filter(id => devices.some(device => device.id === id));
      if (selectedDeviceIDs.length !== nextSelectedDeviceIDs.length) {
        onDevicesSelected(nextSelectedDeviceIDs);
      }
    }
  }

  _handleChange = ({target:{options}}) => {
    const length = options.length;
    const selected = [];
    for (let i = 0; i < length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    this.props.onDevicesSelected(selected);
  };

  render() {
    const {devices, selectedDeviceIDs} = this.props;
    return (
      <select className='pure-input-1' multiple value={selectedDeviceIDs} onChange={this._handleChange}><option disabled key='ios-hack' value='ios-hack' />{devices.map(device =>
        <option key={device.id} value={device.id}>{device.name || device.account}</option>)}
      </select>
    );
  }
}