'use strict';
import React from 'react';
import PropTypes from 'prop-types';

const parentPropTypes = {
  children: PropTypes.node
};

export const ActionLabel = ({children}) => (
  <span className='action-label'>{children}</span>
);

ActionLabel.propTypes = parentPropTypes;

export const ActionIcon = ({children}) => (
  <span className='action-icon'>{children}</span>
);

ActionIcon.propTypes = parentPropTypes;

export const ActionButton = ({children, onClick, rightAligned}) => (
  <div className={'action-button' + (rightAligned ? ' right-aligned' : '')} onClick={onClick}>{children}</div>
);

ActionButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  rightAligned: PropTypes.bool
};

export const ActionButtons = ({children}) => (
  <div className='action-buttons'>{children}</div>
);

ActionButtons.propTypes = parentPropTypes;

export const Separator = () => (
  <div className='separator' />
);
