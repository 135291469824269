'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  unnamedCategory: {
    id: 'pages.events.unnamedCategory',
    description: 'Placeholder text for unnamed categories',
    defaultMessage: '{id} kategória'
  }
});

export default class CategoryFilter extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.string
    })).isRequired,
    selectedCategoryIDs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    onCategoriesSelected: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedCategoryIDs: []
  };

  componentDidUpdate(prevProps) {
    const {categories, selectedCategoryIDs, onCategoriesSelected} = this.props;

    if (prevProps.categories !== categories) {
      const nextSelectedCategoryIDs = selectedCategoryIDs.filter(id => categories.some(category => category.key === id));
      if (selectedCategoryIDs.length !== nextSelectedCategoryIDs.length) {
        onCategoriesSelected(nextSelectedCategoryIDs);
      }
    }
  }

  _handleChange = ({target:{options}}) => {
    const length = options.length;
    const selected = [];
    for (let i = 0; i < length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    this.props.onCategoriesSelected(selected);
  };

  render() {
    const {categories, selectedCategoryIDs} = this.props;
    return (
      <select className='pure-input-1' multiple value={selectedCategoryIDs} onChange={this._handleChange}><option disabled key='ios-hack' value='ios-hack' />{categories.map(category =>
        <FormattedMessage key={category.key} {...messages.unnamedCategory} values={{id: category.key}}>{defaultName =>
          <option value={category.key} key={category.key}>{category.value || defaultName}</option>}
        </FormattedMessage>)}
      </select>
    );
  }
}