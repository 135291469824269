'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Card, CardDoubleItem, CardItem, CardSubItem, CardGizmo} from './card';

export default class DeviceCard extends Component {
  static propTypes = {
    device: PropTypes.shape({
      account: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    onClick: PropTypes.func.isRequired
  };

  _handleClick = () => this.props.onClick(this.props.device);

  render() {
    const {device: {account, name}} = this.props;
    return (
      <Card onClick={this._handleClick}>{name === account ?
        <CardDoubleItem>{name}</CardDoubleItem> : [
          <CardSubItem key='account'>{account}</CardSubItem>,
          <CardItem key='name'>{name}</CardItem>]}
        <CardGizmo />
      </Card>
    );
  }
}