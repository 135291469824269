'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';
import arrayEquals from 'array-equal';

import {HelpIcon} from '../help-icon';

const messages = defineMessages({
  descriptionFilter: {
    id: 'pages.events.descriptionFilter',
    description: 'Placeholder for the event description filter input field.',
    defaultMessage: 'Szűrés esemény leírás szerint'
  },
  descriptionFilterHelp: {
    id: 'pages.events.descriptionFilter.help',
    description: 'Popup help content for event description filter',
    defaultMessage: 'Több kifejezés is megadható szóközzel elválasztva.'
  }
});

class DescriptionFilter extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    onFilterChanged: PropTypes.func.isRequired
  };

  state = {
    value: '',
    parts: []
  };

  _handleChange = ({target:{value}}) => {
    this.setState({value});

    const {parts} = this.state;
    const {onFilterChanged} = this.props;

    const nextParts =  value.split(' ').map(part => part.trim().toLowerCase()).filter(part => part);
    
    if (!arrayEquals(parts, nextParts)) {
      this.setState({parts: nextParts});
      onFilterChanged(nextParts);
    }
  };

  render() {
    const {intl:{formatMessage}} = this.props;
    const {value} = this.state;

    return (
      <div className='input-with-help'>
        <input
            type='text'
            placeholder={formatMessage(messages.descriptionFilter)}
            value={value}
            onChange={this._handleChange}
            className='pure-input-1'
            autoCorrect='off' autoCapitalize='none'
        />
        <HelpIcon title={formatMessage(messages.descriptionFilter)} help={formatMessage(messages.descriptionFilterHelp)} />
      </div>
    );
  }
}

export default injectIntl(DescriptionFilter);
