'use strict';
import React, {Component } from 'react';
import PropTypes from 'prop-types';
import ContextWrapper from './context-wrapper.jsx';

import './help-menu.css';

export default class HelpMenu extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    closeAction: PropTypes.func.isRequired
  };

  render() {
    const {title, text, closeAction} = this.props;
    return (
      <ContextWrapper title={title} closeAction={closeAction}>
        <div className='context-text'>{text}</div>
      </ContextWrapper>
    );
  }
}