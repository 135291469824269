'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  period: {
    id: 'pages.events.filterPeriod',
    description: 'Event filter period (n minutes, x days, etc.) selector\'s label.',
    defaultMessage: 'Időszak'
  },
  hours: {
    id: 'pages.events.period.hours',
    description: 'N hours (1 hour, 2 hours, etc.).',
    defaultMessage: '{hours} óra'
  },
  days: {
    id: 'pages.events.period.days',
    description: 'N days (1 day, 6 days, etc.).',
    defaultMessage: '{days} nap'
  }
});

const PeriodFilter = ({intl:{formatMessage}, periods, selectedPeriod, onPeriodSelected}) => (
  <label>
    <FormattedMessage {...messages.period} />
    <select className='pure-input-1' value={selectedPeriod} onChange={onPeriodSelected}>{periods.map(period =>
      <option key={period.hours} value={period.hours}>
        {formatMessage.apply(null, period.hours < 24 ? [messages.hours, period] : [messages.days, {days: period.hours / 24}])}
      </option>)}
    </select>
  </label>
);

PeriodFilter.propTypes = {
  intl: PropTypes.object.isRequired,
  periods: PropTypes.arrayOf(PropTypes.shape({
    hours: PropTypes.number.isRequired
  }).isRequired),
  selectedPeriod: PropTypes.number.isRequired,
  onPeriodSelected: PropTypes.func.isRequired
};

export default injectIntl(PeriodFilter);

